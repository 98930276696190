import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    language: "ar"
}

const languageSlice = createSlice({
    initialState,
    name: "language",
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload;
        }
    }
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;