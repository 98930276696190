export const fakeCredintials = [
  {
    name: "بسيطة - لوحة التحكم",
    // jop: "مستشار تسويقي",
    image:
      "https://res.cloudinary.com/duovxefh6/image/upload/v1693605788/man_lpskqh.png",
    email: "baseeta@admin.com",
    password: "7410",
    type: "admin"
  }
];

export const loggedInData = localStorage.getItem("logAuth")
  ? JSON.parse(localStorage.getItem("logAuth"))
  : null;

export const LoggedIn = loggedInData